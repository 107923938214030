import axios from 'axios';

const axiosInt = axios.create({
  baseURL: process.env.REACT_APP_BACKEND_URL
});

axiosInt.interceptors.response.use(
  (response) => response,
  (error) => Promise.reject((error.response && error.response.data) || 'There is an error!')
);

export default axiosInt;
